<template>
  <div class="mbl-menu">
    <div class="mbl-menu-desktop">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-9 col-lg-2">
            <img alt="logo" src="~/mlearn/icons/menu/logo.svg" class="logo pointer" @click="goToPage('/')">
          </div>
          <div class="col-lg-7 d-none d-lg-block">
            <div class="list-menu">
              <nuxt-link to="/khoa-hoc" class="menu-item" :class="{'nuxt-link-exact-active': homeActive || videoActive}">Khóa học</nuxt-link>
              <nuxt-link to="/tai-lieu" class="menu-item" :class="{'nuxt-link-exact-active': documentActive}">Tài liệu</nuxt-link>
              <nuxt-link to="/bo-de" class="menu-item" :class="{'nuxt-link-exact-active': quizActive}">Bộ đề</nuxt-link>
              <!-- <nuxt-link to="/blog" class="menu-item" :class="{'nuxt-link-exact-active': blogActive}">Blog</nuxt-link> -->
            </div>
          </div>
          <div class="col-3 text-right d-none" :class="{'d-lg-flex justify-content-end': user, 'd-lg-block': !user}">
            <template v-if="!user">
              <span class="register pointer" @click.prevent="goToPage('/goi-cuoc')"><img alt="profile" src="~/mlearn/icons/menu/profile.svg" class="profile">Đăng ký</span>
              <span class="split">|</span>
              <span class="login pointer" v-b-modal.modal-login>Đăng nhập</span>
            </template>
            <template v-else>
              <Notify class="mr-3"/>
              <Profile/>
            </template>
          </div>
          <div class="col-3 text-right d-block d-lg-none">
            <!-- <img src="~/mlearn/icons/menu/search.svg" @click="goToPage('/tim-kiem')" class="mr-3" :class="{'d-none': $route.name === 'tim-kiem'}" alt=""> -->
            <img src="~/mlearn/icons/menu/open-menu.svg" class="pointer" @click.stop="openMenu" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="mbl-menu-mobile min-vh-100 overflow-auto to-left" :class="{'open':showMenu}">
      <img src="~/mlearn/icons/menu/close-menu.svg" class="pointer position-absolute close-menu" @click.stop="closeMenu" alt="">
      <div class="list-menu container">
        <div class="menu-item" v-if="user">
          <div class="form-data align-items-center" @click.prevent="goToPage('/thong-tin-ca-nhan')">
            <el-image class="avatar rounded-circle" :src="user.avatar || NoAvatar" alt="Avatar" ></el-image>
            <div class="content">
              <div class="user-name display-html display-1-line">{{user.fullName}}</div>
              <div class="user-info">Xem thông tin cá nhân</div>
            </div>
          </div>
        </div>
        <div class="menu-item menu" :class="homeActive || videoActive ? 'active': ''">
          <div @click.prevent="goToPage('/khoa-hoc')">Khóa học</div>
        </div>
        <div class="menu-item menu" :class="documentActive ? 'active': ''">
          <div @click.prevent="goToPage('/tai-lieu')">Tài liệu</div>
        </div>
        <div class="menu-item menu" :class="quizActive ? 'active': ''">
          <div @click.prevent="goToPage('/bo-de')">Bộ đề</div>
        </div>
        <!-- <div class="menu-item menu" :class="blogActive ? 'active': ''">
          <div @click.prevent="goToPage('/blog')">Blog</div>
        </div> -->
        <div class="menu-item line"></div>
        <template v-if="user">
<!--          <div class="menu-item" :class="routeName === 'hoi-bai-cua-toi' ? 'active': ''">-->
<!--            <div @click.prevent="goToPage('/hoi-bai-cua-toi')" class="d-flex align-items-baseline">-->
<!--              <img src="~/mlearn/icons/menu/profile/my-question.svg" alt="Hỏi bài của tôi" class="mr-2"/>-->
<!--              Hỏi bài của tôi-->
<!--            </div>-->
<!--          </div>-->
          <div class="menu-item" :class="routeName === 'goi-cuoc' ? 'active': ''">
            <div @click.prevent="goToPage('/goi-cuoc')" class="d-flex align-items-baseline">
              <img src="~/mlearn/icons/menu/profile/my-sub.svg" alt="Gói cước" class="mr-2"/>
              Gói cước
            </div>
          </div>
          <div class="menu-item" :class="routeName === 'thu-vien-cua-toi' ? 'active': ''">
            <div @click.prevent="goToPage('/thu-vien-cua-toi')" class="d-flex align-items-baseline">
              <img src="~/mlearn/icons/menu/profile/my-library.svg" alt="Thư viện của tôi" class="mr-2"/>
              Thư viện của tôi
            </div>
          </div>
          <div class="menu-item line"></div>
          <div class="menu-item" :class="routeName === 'doi-mat-khau' ? 'active': ''">
            <div @click.prevent="goToPage('/doi-mat-khau')" class="d-flex align-items-baseline">
              <img src="~/mlearn/icons/menu/profile/change-pass.svg" alt="Đổi mật khẩu" class="mr-2"/>
              Đổi mật khẩu
            </div>
          </div>
          <div class="menu-item">
            <div @click.prevent="logout()" class="d-flex align-items-baseline">
              <img src="~/mlearn/icons/menu/profile/logout.svg" alt="Đăng xuất" class="mr-2"/>
              Đăng xuất
            </div>
          </div>
        </template>
        <template v-else>
          <div class="menu-item">
            <div v-b-modal.modal-login>
              Đăng nhập
            </div>
          </div>
          <div class="menu-item">
            <div @click.prevent="goToPage('/goi-cuoc')">
              Đăng ký
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="menu-backdrop" v-if="showMenu" @click="closeMenu"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { removeToken, removeVerify } from '~/common/utils/auth'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'
import Profile from '~/components/mlearn/layout/menu/profile.vue'
import Notify from '~/components/mlearn/layout/menu/notify.vue'
export default {
  components: {
    Profile,
    Notify
  },
  data () {
    return {
      NoAvatar,
      showMenu: false,
      showApp: true
    }
  },
  head () {
    return {
      bodyAttrs: {
        class: 'ml-scrollbar'
      }
    }
  },
  computed: {
    ...mapState([
      'user'
    ]),
    homeActive () {
      const arr = ['index', 'cau-hoi-slug', 'tra-loi-slug', 'bang-xep-hang', 'hoi-bai-cua-toi', 'noi-quy-hoi-bai', 'huong-dan-cong-diem', 'thong-tin-ca-nhan', 'goi-cuoc', 'doi-mat-khau', 'lich-su-thanh-toan', 'dang-ky-gia-su', 'thong-tin-khac', 'search']
      return arr.includes(this.$route.name)
    },
    videoActive () {
      return /khoa-hoc/.test(this.$route.path)
    },
    documentActive () {
      return /tai-lieu/.test(this.$route.path)
    },
    quizActive () {
      return /bo-de/.test(this.$route.path)
    },
    blogActive () {
      return /blog/.test(this.$route.path)
    },
    routeName () {
      return this.$route.name
    }
  },
  methods: {
    goToPage (path = '/') {
      this.closeMenu()
      this.$router.push({ path })
    },
    openMenu () {
      this.showMenu = true
      document.querySelector('body').classList.add('open-sidebar')
    },
    closeMenu () {
      if (this.showMenu) {
        this.showMenu = false
        document.querySelector('body').classList.remove('open-sidebar')
      }
    },
    logout () {
      removeToken()
      removeVerify()
      window.location.href = '/'
    }
  }
}
</script>

<style lang="scss">
  .mbl-menu{
    background: var(--white);
    position: fixed;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    top: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    @media (max-width: 576px) {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    }
    .mbl-menu-desktop{
      padding:15px 0 17px;
      @media (max-width:1366px) {
        padding:12px 0 12px;
      }
      @media (max-width:992px) {}
      .logo{
        max-height: 30px;
        @media (max-width:1366px) {
          max-height: 30px;
        }
        @media (max-width:992px) {}
        @media (max-width:576px) {
          max-height: 25px;
        }
      }
      .list-menu{
        .menu-item{
          font-weight: 500;
          font-size: 18px;
          margin-right: 60px;
          @media (max-width:1366px) {
            font-size: 16px;
            margin-right: 35px;
          }
          @media (max-width:992px) {}
          &:last-child{
            margin-right: 0;
          }
          &.nuxt-link-exact-active{
            color: var(--primary);
            font-weight: 700;
          }
        }
      }
      .register{
        font-weight: 500;
        font-size: 16px;
        @media (max-width:1366px) {
          font-size: 15px;
        }
        @media (max-width:992px) {}
        img {
          margin-right: 1rem;
          @media (max-width:1366px) {
            margin-right: .7rem;
          }
          @media (max-width:992px) {}
        }
      }
      .split{
        color:#CED4DA;
        margin:0 1rem;
        @media (max-width:1366px) {
          margin:0 .7rem;
        }
        @media (max-width:992px) {}
      }
      .login{
        font-weight: 500;
        font-size: 16px;
        @media (max-width:1366px) {
          font-size: 15px;
        }
        @media (max-width:992px) {}
      }
    }
    .mbl-menu-mobile{
      background: var(--white);
      position: fixed;
      width: 0px;
      top: 0;
      bottom:0;
      z-index: 9999;
      transition: all .4s ease;
      padding:0;
      &.to-left{
        right: 0;
        &.open{
          width: 240px;
        }
      }
      &.open{
        .list-menu{
          display: block;
        }
      }
      .close-menu{
        right: 16px;
        top: 15px;
        width:14px;
      }
      .list-menu{
        display: none;
        padding-top:40px;
        padding-bottom:10px;
        padding-left: 1rem;
        padding-right: 1rem;
        .menu-item{
          padding-top:10px;
          padding-bottom:10px;
          font-weight: 500;
          font-size: 16px;
          &.menu{
            padding-left:16px;
            padding-right:16px;
            &.active{
              color: var(--white);
              background: var(--primary);
              border-radius: 8px;
            }
          }
          &.line{
            padding-top: 0;
            padding-bottom: 0;
            margin: 8px 0;
            border: 1px solid #EBEBEB;
          }
          &.active{
            color: var(--primary);
          }
          &:hover{
            background: var(--primary);
            color: var(--white);
            border-radius: 8px;
          }
          .avatar{
            width:46px;
            height:46px;
          }
          .content{
            margin-left: 5px;
            .user-name{
              font-weight: 500;
              font-size: 16px;
              max-width: 150px;
            }
            .user-info{
              font-size: 14px;
              color: #868789;
            }
          }
        }
      }
    }
    .menu-backdrop{
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 4000;
      background-color: #000;
      opacity: .5;
    }
  }
</style>
