<template>
  <footer>
    <div class="container">
      <img alt="logo" src="~/mlearn/icons/footer/logo.svg" class="logo pointer" @click="goToPage('/')">
      <div class="row">
        <div class="col-lg-9 col-xl-5">
          <div class="company-info">
            <div><span>Công ty chủ quản: </span>Công Ty Cổ Phần DATANOVA VIỆT NAM</div>
            <div><span>Trụ sở chính: </span>Tầng 5, Tòa nhà Elcom, ngõ 15 Duy Tân, Phường Dịch Vọng Hậu, Quận Cầu Giấy, Hà Nội.</div>
            <div>
              <span>Giấy chứng nhận ĐKKD: </span>0106659610 do Sở KHĐT thành phố Hà Nội cấp ngày 08/10/2014{{'\n'}}Thay đổi lần thứ 7 ngày 09/05/2024
            </div>
            <!-- <div><img alt="notify" src="~/mlearn/icons/footer/notify.svg" class="notify"></div> -->
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-3 line-mobile">
          <div class="block-data">
            <div class="title">THÔNG TIN</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item">
                <nuxt-link to="/thong-tin-khac">Giới thiệu</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/goi-cuoc">Dịch vụ MobiLearn</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/thong-tin-khac?tab=1">Điều khoản sử dụng</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/thong-tin-khac?tab=2">Chính sách bảo mật dữ liệu cá nhân</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/thong-tin-khac?tab=3">Thỏa thuận cung cấp<br/>dịch vụ mạng xã hội</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/huong-dan-su-dung">Hướng dẫn sử dụng</nuxt-link>
              </li>
              <!-- <li class="item">
                <nuxt-link to="/">Dịch vụ nằm trong hệ sinh thái mobiedu.vn</nuxt-link>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-2 line-mobile">
          <div class="block-data">
            <div class="title">DỊCH VỤ CUNG CẤP</div>
            <ul class="list-item list-unstyled overflow-auto">
<!--              <li class="item">-->
<!--                <nuxt-link to="/hoi-bai-cua-toi">Hỏi bài gia sư</nuxt-link>-->
<!--              </li>-->
              <li class="item">
                <nuxt-link to="/khoa-hoc">Khóa học</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/tai-lieu">Tài liệu</nuxt-link>
              </li>
              <li class="item">
                <nuxt-link to="/bo-de">Bộ đề</nuxt-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 order-lg-last col-xl-2 line-mobile">
          <div class="block-data">
            <div class="title">HỖ TRỢ KHÁCH HÀNG</div>
            <ul class="list-item list-unstyled overflow-auto">
              <li class="item"><img alt="call" src="~/mlearn/icons/footer/call.svg" class="call"> 18009230 (miễn phí)</li>
            </ul>
          </div>
          <div class="block-data d-none d-xl-block">
            <div class="title">TẢI ỨNG DỤNG MOBILEARN</div>
            <ul class="list-item list-unstyled">
              <li class="item form-data">
                <img alt="qrcode" src="~/mlearn/icons/footer/qrcode.svg" class="qrcode">
                <div class="content platform">
                  <div>
                    <img alt="chplay" src="~/mlearn/icons/footer/chplay.svg" class="chplay w-100">
                  </div>
                  <div class="pt-1">
                    <img alt="appstore" src="~/mlearn/icons/footer/appstore.svg" class="appstore w-100">
                  </div>
                </div>
              </li>
              <li class="item note">Quét mã QR code để cài đặt </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-2 d-block d-xl-none line-mobile">
          <div class="block-data">
            <div class="title">TẢI ỨNG DỤNG MOBILEARN</div>
            <ul class="list-item list-unstyled mb-0">
              <li class="item form-data">
                <img alt="qrcode" src="~/mlearn/icons/footer/qrcode.svg" class="qrcode">
                <div class="content platform">
                  <div>
                    <img alt="chplay" src="~/mlearn/icons/footer/chplay.svg" class="chplay">
                  </div>
                  <div class="pt-2">
                    <img alt="appstore" src="~/mlearn/icons/footer/appstore.svg" class="appstore">
                  </div>
                </div>
              </li>
              <li class="item note">Quét mã QR code để cài đặt </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="copy-right"><img alt="copy-right" src="~/mlearn/icons/footer/copy-right.svg"> Bản quyền thuộc về công ty cổ phần Elcom</div>
    </div>
<!--    <span v-if="showIconAddQuestion" class="global-add-question pointer position-fixed d-flex align-items-center justify-content-center" @click="addQuestion">-->
<!--      <img src="~/mlearn/icons/add-question.svg" alt="">-->
<!--    </span>-->
    <template v-if="!user">
      <ModalLogin @login="handerLoginSocial" />
      <ModalRegister @login="handerLoginSocial"/>
      <ModalForget />
      <ModalVerifySocial :form="form" />
    </template>
    <ModalQuestion/>
    <ModalRedirectSub/>
    <ModalVerifyGuru v-if="!isVerifiedMobile"/>
    <ModalVerifyMobile />
    <BackToTop :right="isMobile ? '10%' : '106px'" />
    <ModalCatalog/>
  </footer>
</template>

<script>
import Cookies from 'js-cookie'
import ModalForget from '~/components/mlearn/modal/forget.vue'
import ModalLogin from '~/components/mlearn/modal/login.vue'
import ModalRegister from '~/components/mlearn/modal/register.vue'
import ModalQuestion from '~/components/mlearn/modal/add-question.vue'
import ModalRedirectSub from '~/components/mlearn/modal/sub.vue'
import ModalVerifyGuru from '~/components/mlearn/modal/verify-guru.vue'
import ModalVerifyMobile from '~/components/mlearn/modal/verify-mobile.vue'
import ModalVerifySocial from '~/components/mlearn/modal/verify-social.vue'
import ModalCatalog from '~/components/mlearn/modal/catalog.vue'

import { getLinkDetect3G, login3G } from '~/common/api/viettel'
export default {
  components: {
    ModalLogin,
    ModalRegister,
    ModalForget,
    ModalQuestion,
    ModalRedirectSub,
    ModalVerifyMobile,
    ModalVerifyGuru,
    ModalVerifySocial,
    ModalCatalog
  },
  data () {
    return {
      form: null
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    isMobile () {
      return this.$store.state.isMobile
    },
    isVerifiedMobile () {
      return this.$store.state.isVerifiedMobile
    },
    showIconAddQuestion () {
      const accpet = ['index', 'khoa-hoc', 'khoa-hoc-chi-tiet-slug', 'tai-lieu', 'tai-lieu-chuyen-de-slug', 'bo-de', 'bo-de-chuyen-de-slug']
      return accpet.includes(this.$route.name)
    }
  },
  created () {
    const redirect = Cookies.get('redirectLogin')
    if (this.isMobile && !this.$store.state.authenticated && !redirect) {
      Cookies.set('redirectLogin', true, { expires: 1 / 24 })
      // this.$router.push('/dang-nhap')
    }
  },
  mounted () {
    if (this.isMobile && !this.$store.state.authenticated) {
      const data = this.$route.query.DATA
      const sig = this.$route.query.SIG
      if (!data && !sig) {
        getLinkDetect3G()
          .then((response) => {
            const data = response.data
            if (data) {
              const link = data.link
              if (link) {
                window.location.href = link
              } else {
                this.$bvModal.show('modal-login')
              }
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        const params = {
          DATA: data,
          SIG: sig
        }
        login3G(params)
          .then((response) => {
            if (response.data) {
              if (response.data.isLogin === 1) {
                this.$store.commit('SET_AUTHENTICATED', true)
                this.$store.commit('SET_TOKEN', response.data.accessToken)
                this.$router.go(this.$router.currentRoute)
              } else {
                this.$store.commit('SET_VIETTEL', response.data.mobile)
                this.$bvModal.show('modal-catalog')
              }
            } else {
              this.$bvModal.show('modal-login')
            }
          })
          .catch(() => {
          })
      }
    } else if (!this.$store.state.authenticated) {
      this.$bvModal.show('modal-login')
    }
  },
  methods: {
    goToPage (path = '/') {
      this.$router.push({ path })
    },
    addQuestion () {
      this.$store.dispatch('setShowQuestionDialog', true)
    },
    handerLoginSocial (data) {
      this.form = data
    }
  }
}
</script>

<style lang="scss" scoped>
  footer{
    background: #fff;
    padding:28px 0 15px;
    @media (max-width:576px) {
      padding:15px 0 10px;
    }
    .logo{
      max-height: 30px;
      @media (max-width:576px) {
        max-height: 25px;
      }
    }
    .row {
      .line-mobile{
        @media (max-width:576px) {
          border-top: 1px solid rgba(255, 255, 255, .2);
        }
        // @media (max-width:991px) {
        //   border-top: 1px solid rgba(255, 255, 255, .2);
        // }
      }
      > [class*="col-"]{
        padding-top: 2rem;
        @media (max-width:576px) {
          padding-top: 1rem;
        }
        .company-info{
          padding-right: 50px;
          @media (max-width:1366px) {
            padding-right: unset;
          }
          @media (max-width:576px) {
            padding-right: unset;
          }
          > div {
            font-size: 14px;
            white-space: break-spaces;
            margin-bottom:10px;
            @media (max-width:576px) {
              margin-bottom:8px;
            }
            &.name{
              font-weight: bold;
              font-size: 16px;
              @media (max-width:576px) {
                font-size: 14px;
              }
            }
            span{
              font-weight: bold;
              @media (max-width:576px) {}
            }
            .notify{
              max-height: 60px;
              @media (max-width:576px) {
                max-height: 40px;
              }
            }
          }
        }
        .block-data{
          margin-top: 35px;
          @media (max-width:576px) {
            margin-top: 25px;
          }
          &:first-child{
            margin-top:0;
            @media (max-width:576px) {}
          }
          .title{
            font-weight: bold;
            text-transform: uppercase;
            font-size: 14px;
            margin-bottom: 18px;
            opacity: .6;
            @media (max-width:576px) {
              margin-bottom: 10px;
            }
          }
          .list-item{
            margin-bottom: 0;
            @media (max-width:576px) {
              white-space: nowrap;
            }
            .item{
              font-weight: 500;
              font-size: 14px;
              margin-bottom: 8px;
              @media (max-width:576px) {
                display: inline-block;
                margin-right: 12px;
                &:last-child{
                  margin-right: 0;
                }
              }
              &:last-child{
                // margin-bottom: 0;
                @media (max-width:576px) {}
              }
              &.note{
                font-style: italic;
                font-weight: normal;
                font-size: 12px;
                @media (max-width:576px) {}
              }
              a {
                font-weight: 500;
                font-size: 14px;
              }
              .qrcode{
                width: 75px;
              }
              .platform{
                margin-left: 10px;
                @media (max-width:576px) {}
                img {
                  margin-bottom: 10px;
                  @media (max-width:576px) {}
                  &:last-child{
                    margin-bottom: 0;
                  }
                }
              }
              .notify{
                max-width: 120px;
              }
            }
          }
        }
      }
    }
    .line{
      height: 1px;
      margin:12px 0;
      background: rgba(000, 000, 000, .2);
      @media (max-width:576px) {}
    }
    .copy-right{
      font-size: 14px;
      @media (max-width:576px) {}
    }
  }
  .global-add-question{
    width:54px;
    height: 54px;
    background: #EE0032;
    border: 1px solid #9C0827;
    box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transform: translateY(-50%);
    right: 100px;
    top: 50%;
    @media (max-width: 576px) {
      right: 16px;
      top: 70%;
    }
    img {
      max-width: 60%;
    }
  }
</style>
